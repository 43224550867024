import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import { BText } from "./chakra/BText"

export function ProjectCard(props) {
  const [isMobile, setIsMobile] = useState(false)
  const [cardSpanOffset, setCardSpanOffset] = useState({ x: 0, y: 0 })
  const cardRef = useRef()

  useEffect(() => {
    if (window) {
      if (window.innerWidth > 700) {
        setIsMobile(false)
      } else {
        setIsMobile(true)
      }
    }
  }, [])

  const onMouseEnterOrLeave = useCallback(
    event => {
      if (cardRef.current) {
        const rect = cardRef.current.getBoundingClientRect?.()
        setCardSpanOffset({
          x: event.clientX - rect.left,
          y: event.clientY - rect.top,
        })
      }
    },
    [cardRef]
  )

  const children = (
    <>
      <AniLink
        paintDrip
        to={props.node.slug}
        hex={props.node.backgroundColor.color}
        style={{
          flex: 1,
          zIndex: 10000,
        }}
      >
        <span
          style={{
            backgroundColor: `${props.node.coverColor.color}`,
            top: cardSpanOffset.y,
            left: cardSpanOffset.x,
          }}
        />
        <div
          className={"project-card-content"}
          style={{ color: `${props.node.coverTextColor.color}` }}
        >
          <BText as={"h1"} fontSize={"46px"}>
            {props.node.title}
          </BText>
        </div>
      </AniLink>
    </>
  )

  return useMemo(() => {
    const id = isMobile ? "mobile-article" : "desktop-article"
    const backgroundImage = isMobile
      ? `url(${props.strapiUrl}${props.node.mobileThumbnail?.url})`
      : `url(${props.strapiUrl}${props.node.thumbnail.url})`
    return (
      <article
        id={id}
        className={`project-card ${props.count % 3 === 0 &&
          "project-card-large"} ${props.postClass} with-image`}
        style={{
          backgroundImage,
        }}
        ref={cardRef}
        onMouseEnter={onMouseEnterOrLeave}
        onMouseLeave={onMouseEnterOrLeave}
      >
        {children}
      </article>
    )
  }, [isMobile, children])
}

export default ProjectCard
