import React, { useEffect, useState } from "react"
import { graphql, StaticQuery } from "gatsby"
import { Fade } from "react-awesome-reveal"

import Layout from "../components/Layout"
import Seo from "../components/Seo"
import ProjectCard from "../components/ProjectCard"

import "../utils/normalize.css"
import "../utils/css/screen.css"
import { Helmet } from "react-helmet/es/Helmet"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import { BText } from "../components/chakra/BText"

function HomePage({ data, location }) {
  const projects = data.allStrapiProjects.edges
  let projectCounter = 0

  const [isDesktop, setIsDesktop] = useState(false)
  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    if (window) {
      if (window.innerWidth > 700) {
        setIsDesktop(true)
        setIsMobile(false)
      } else {
        setIsMobile(true)
        setIsDesktop(false)
      }
    }
  }, [])

  const { site, allStrapiHomepage } = data || {}
  const { url } = allStrapiHomepage?.edges?.node?.seo?.metaImage || {}
  const { siteMetadata } = site || {}
  const { edges } = allStrapiHomepage || {}
  const [edge] = edges || {}

  return (
    <Layout
      title={siteMetadata.title}
      strapiUrl={siteMetadata.strapiUrl}
      siteUrl={location.href}
      themeColor={"#ffffff"}
    >
      <Helmet>
        <link
          rel={"apple-touch-icon"}
          sizes={"180x180"}
          href={"/apple-touch-icon.png"}
        />
        <link
          rel={"icon"}
          type={"image/png"}
          sizes={"32x32"}
          href={"/favicon-32x32.png"}
        />
        <link
          rel={"icon"}
          type={"image/png"}
          sizes={"16x16"}
          href={"/favicon-16x16.png"}
        />
        <link rel={"manifest"} href={"/site.webmanifest"} />
        <link
          rel={"mask-icon"}
          href={"/safari-pinned-tab.svg"}
          color={"#ffd600"}
        />
        <meta name={"msapplication-TileColor"} content={"#2d89ef"} />

        {/*Open Graph / Facebook*/}
        <meta
          property={"og:image"}
          content={
            "https://bitlight-web-strapi.herokuapp.com/uploads/meta_Image_811a4ade83.png"
          }
        />

        {/*Twitter*/}
        <meta property={"twitter:card"} content={"summary_large_image"} />
        <meta
          property={"twitter:image"}
          content={
            "https://bitlight-web-strapi.herokuapp.com/uploads/meta_Image_811a4ade83.png"
          }
        />
      </Helmet>
      <Seo
        title={edge.node.seo.metaTitle}
        description={edge.node.seo.metaDescription}
        keywords={[
          "bitlight",
          "software",
          "development",
          "it",
          "company",
          "mobile",
          "web",
          "application",
          "program",
          "programiranje",
          "tvrtka",
          "kompanija",
          "mobilna aplikacija",
          "mobilne aplikacije",
        ]}
        image={"https://bitlight-web-strapi.herokuapp.com/uploads/meta_Image_811a4ade83.png"}
      />
      <section id={"hero"} className={"jumbotron max-width"}>
        <Fade triggerOnce={true} direction={"left"} duration={1000} delay={500}>
          <h1 className={"hero-title"}>
            <BText as={"span"} className={"text-color-main"}>
              {edge.node.heading1}
            </BText>
            <br />
            <BText as={"span"}>{edge.node.heading2}</BText>
          </h1>
        </Fade>
      </section>

      <Fade
        triggerOnce={true}
        direction={isDesktop ? "up" : "left"}
        duration={1000}
        delay={1500}
      >
        <section id={"projects"} className={"project-feed max-width"}>
          {projects.map(({ node }) => {
            projectCounter++
            return (
              <ProjectCard
                key={node.slug || "/"}
                count={projectCounter}
                node={node}
                strapiUrl={siteMetadata.strapiUrl}
                postClass={"post"}
              />
            )
          })}
        </section>
      </Fade>

      <section id={"about"}>
        <div
          className={"max-width about-content"}
          style={{ paddingBottom: "10vh" }}
        >
          <Fade
            triggerOnce={true}
            direction={isDesktop ? "left" : undefined}
            duration={1000}
            delay={200}
          >
            <BText
              as={"h1"}
              id={"contact-hero-title"}
              style={{
                color: "white",
              }}
            >
              Development & Design agency.
            </BText>
            <br />
            <BText
              as={"h1"}
              id={"contact-hero-subtitle"}
              style={{
                color: "white",
              }}
            >
              Zagreb Office.
            </BText>
            <br />
            <BText
              as={"h1"}
              id={"contact-hero-subtitle-2"}
              style={{
                color: "white",
              }}
            >
              Bitlight is an emerging company in the field of software solutions
              for process analysis, optimization and client outreach based in
              Zagreb, Croatia. We utilize cutting-edge technology stacks to
              build performant, scalable and maintainable services. We aim to
              surpass your and our limitations from personal transformation of
              thought to tangible results.
            </BText>
          </Fade>
          <br />
        </div>
        <div className={"video-container max-width-video"}>
          <video autoPlay muted loop className={"max-width-video-child"}>
            <source
              src={`${siteMetadata.strapiUrl}/uploads/bitlight_video_01_1_c1a86f6891.mp4`}
              type={"video/mp4"}
            />
          </video>
        </div>
      </section>
      <section id={"contact-hero"} style={{ backgroundColor: "white" }}>
        <div id={"contact-hero-spacer-top"} />
        <div className={"max-width"} style={{ width: "100vw" }}>
          <Fade duration={1000} delay={200} triggerOnce={true}>
            <BText as={"h1"} id={"contact-hero-title"}>
              We turn your thoughts and ideas into awesome digital products.
            </BText>
            <br />
            <BText
              as={"h1"}
              id={"contact-hero-subtitle"}
              className={"hero-contact-us"}
            >
              Seeing as you&apos;re interested in the digital revolution, why
              not take the first step and reach out to us?
            </BText>
            <div className={"contact-us"}>
              <AniLink
                id={"contactButton"}
                paintDrip
                to={"/contact"}
                hex={"#ffd600"}
                style={{
                  flex: 1,
                  zIndex: 10000,
                }}
              >
                <BText as={"span"}>Contact</BText>
              </AniLink>
            </div>
          </Fade>
        </div>
        <div id={"contact-hero-spacer-bottom"} />
      </section>
    </Layout>
  )
}

const indexQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
        strapiUrl
        siteUrl
      }
    }
    allStrapiHomepage {
      edges {
        node {
          heading1
          heading2
          seo {
            metaDescription
            metaTitle
            metaImage {
              url
            }
          }
        }
      }
    }
    allStrapiProjects(sort: { fields: projectPosition }) {
      edges {
        node {
          id
          shortDescription
          coverColor {
            color
          }
          backgroundColor {
            color
          }
          coverImage {
            url
            alternativeText
          }
          coverTextColor {
            color
          }
          title
          thumbnail {
            alternativeText
            url
          }
          slug
          mobileThumbnail {
            url
            alternativeText
          }
        }
      }
    }
  }
`

function StaticHomePage(props) {
  return (
    <StaticQuery
      query={indexQuery}
      render={data => (
        <HomePage location={props.location} props data={data} {...props} />
      )}
    />
  )
}

export default StaticHomePage
